import { Branch } from 'store/slices/branches';
import { CAMERA, CAMERA_LIST } from 'routes/constant-route';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';
import { isArrayNullOrEmpty } from '@borda/cat-core';

const Locations = delayedLazy(() => import('views/Locations/Locations'));
const AccessPoints = delayedLazy(() => import('views/Locations/AccessPoints/AccessPoints'));
const MapSettingsPage = delayedLazy(() => import('views/Locations/MapSettings/MapSettingsPage'));
const CameraAdd = delayedLazy(
  () => import('views/Locations/Camera/CameraDetail/CameraAdd/CameraAdd')
);
const CameraDetail = delayedLazy(
  () => import('views/Locations/Camera/CameraDetail/CameraDetail/CameraDetail')
);
const Cameras = delayedLazy(() => import('views/Locations/Camera/Cameras/Cameras'));

export default function renderLocationRoutes(rtlsEnabledBranches: Branch[]) {
  return (
    <Route
      element={
        <RouteGuard authKey="locationManagement">
          <SuspenseOutlet />
        </RouteGuard>
      }
      path="location"
    >
      <Route element={<Locations />} path="locations" />
      <Route
        element={
          <RouteGuard hidden={isArrayNullOrEmpty(rtlsEnabledBranches)}>
            <AccessPoints />
          </RouteGuard>
        }
        path="access-points"
      />
      <Route
        element={
          <RouteGuard development hidden={isArrayNullOrEmpty(rtlsEnabledBranches)}>
            <Outlet />
          </RouteGuard>
        }
        path={CAMERA}
      >
        <Route
          element={
            <RouteGuard>
              <Cameras />
            </RouteGuard>
          }
          index
          path={CAMERA_LIST}
        />
        <Route element={<CameraDetail />} path="camera/:id" />
        <Route element={<CameraAdd />} path="camera_add" />
        <Route element={<Navigate replace to={CAMERA_LIST} />} index />
      </Route>
      <Route
        element={
          <RouteGuard hidden={isArrayNullOrEmpty(rtlsEnabledBranches)}>
            <MapSettingsPage />
          </RouteGuard>
        }
        path="map-settings"
      />
      <Route element={<Navigate replace to="locations" />} index />
    </Route>
  );
}
